<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">Структура и органы управления образовательной организацией</div>
        </div>

        <div class="content_18 mb-5">
          <div class="mb-1"><strong class="content_700">Коллегиальные органы</strong> – Педагогический совет, Общее собрание
            работников
          </div>
          <div class="mb-1"><strong class="content_700">Высший коллегиальный орган</strong> – Наблюдательный совет</div>
          <div class=""><strong class="content_700">Единоличный исполнительный орган</strong> – Генеральный директор
          </div>
        </div>

        <div class="mb-5 d-block d-md-none">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <div class="table-wrapper_indicator"></div>
            </div>
          </div>
        </div>

        <div class="table-wrapper table-wrapper_scrolled mb-4">
          <table class="table_bordered" itemprop="structOrgUprav">
            <thead>
            <tr>
              <th>Наименование органа управления / структурного подразделения</th>
              <th>ФИО руководителя структурного подразделения</th>
              <th>Должность руководителя структурного подразделения</th>
              <th>Адрес местонахождения структурного подразделения</th>
              <th>Адрес официального сайта структурного подразделения</th>
              <th>Адреса электронной почты структурного подразделения</th>
              <th>Положение об органе управления/ о структурном подразделении</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="7">Органы управления образовательной организации:</td>
            </tr>
            <tr>
              <td itemprop="name">Генеральный директор</td>
              <td><strong class="content_700" itemprop="fio">НИКИТИНА<br/>Алисия Равилевна</strong></td>
              <td itemprop="post">Генеральный директор АНО ДПО «Школа экспорта Акционерного общества «Российский экспортный центр»</td>
              <td itemprop="addressStr">123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td itemprop="site">www.exportedu.ru/</td>
              <td itemprop="email">info@exportcenter.ru</td>
              <td><a itemprop="divisionClauseDocLink" href="https://exporteduru.servicecdn.ru/media/default/0001/76/ec647a777f754dfef02785f28f7d8c9a5dee3f46.pdf">Решение о назначении генерального директора</a></td>
            </tr>
            <tr>
              <td itemprop="name">Педагогический совет</td>
              <td><strong class="content_700" itemprop="fio">НИКИТИНА<br/>Алисия Равилевна</strong></td>
              <td itemprop="post">Генеральный директор АНО ДПО «Школа экспорта Акционерного общества «Российский экспортный центр»</td>
              <td itemprop="addressStr">123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td itemprop="site">www.exportedu.ru/</td>
              <td itemprop="email">info@exportcenter.ru</td>
              <td><a itemprop="divisionClauseDocLink" href="https://exporteduru.servicecdn.ru/media/default/0002/64/23a3cde73ea5d1dee021927f9255015ad8bb6a32.pdf">Положение о Педсовете.pdf</a></td>
            </tr>
            <tr>
              <td itemprop="name">Общее собрание работников</td>
              <td><strong class="content_700" itemprop="fio">КОМАРОВА<br/> Яна Анатольевна</strong></td>
              <td itemprop="post">Директор по работе с образовательными организациями</td>
              <td itemprop="addressStr">123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td itemprop="site">www.exportedu.ru/</td>
              <td itemprop="email">info@exportcenter.ru</td>
              <td><a itemprop="divisionClauseDocLink" href="https://exporteduru.servicecdn.ru/media/default/0002/65/e5790e1639d55a18cd80402b570acb6cf8da34e8.pdf">Положение об общем собрании работников.pdf</a></td>
            </tr>
            <tr>
              <td colspan="7">Структурные подразделения образовательной организации:</td>
            </tr>
            <tr>
              <td colspan="7">Структурные подразделения отсутствуют</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="content_title content_700 mb-3">Наблюдательный совет</div>
        <div class="content_18 mb-3">
          Наблюдательный совет является постоянно действующим высшим коллегиальным органом управления Школы экспорта РЭЦ, к исключительной компетенции которого относится решение таких вопросов, как определение приоритетных направлений деятельности, основных направлений деятельности Организации, принципов формирования и использования ее имущества; утверждение Стратегии развития Организации и т.д.
        </div>
        <div class="container-grid grid-3 grid-md-1 mb-24">
          <div class="container-grid__item">
            <div class="doc fill-white p-24 container-shadow">
              <div class="doc__content row">
                <div class="col-auto text-center">
                  <div class="ext-wrapper m-0">
                    <img src="/pic/icon-doc.svg"/>

                  </div>
                </div>
                <div class="col">
                  <div class="doc__title">Положение о Наблюдательном совете АНО</div>
                </div>
              </div>
              <div class="doc__actions">
                <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/59/029e1d0b5df8d59923ad86dc01b2b7bb41cbc850.pdf" class="btn btn-outline-primary">Скачать</a>
              </div>
            </div>
          </div>
        </div>

        <hr class="separator"/>

        <div class="content_title content_700 mb-3">Педагогический совет</div>
        <div class="content_18 mb-3">Педагогический совет является постоянно действующим коллегиальным,
          совещательным органом Школы экспорта РЭЦ по управлению образовательным процессом.
        </div>
        <hr class="separator"/>

        <div class="content_title content_700 mb-3">Общее собрание работников</div>
        <div class="content_18 mb-3">Общее собрание работников является коллегиальным органом управления, в состав которого входят все работники Школы экспорта РЭЦ.
        </div>

        <hr class="separator"/>

        <div class="content_title content_700 mb-3">Генеральный директор</div>
        <div class="content_18 mb-12">Генеральный директор осуществляет непосредственное руководство текущей деятельностью Школы экспорта РЭЦ и представляет интересы во взаимоотношениях с юридическими и физическими лицами, государственными органами, иными субъектами.
        </div>

        <hr class="separator"/>

        <div class="content_title content_700 mb-3">Сведения о филиалах</div>
        <div class="content_18 mb-12">Школа экспорта РЭЦ не имеет филиалов
        </div>

        <hr class="separator"/>
        <div class="content_title content_700 mb-3">Сведения о представительствах</div>
        <div class="content_18 mb-12">Школа экспорта РЭЦ не имеет представительств
        </div>

        <div class="my-5 d-block">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>

        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import ApiService from "../../../services/api.service";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoStructure",
  components: {CompanyNavigation, PageFooter},
  data() {
    return {
      struct: null
    }
  },
  mounted() {
    ApiService.get('infocategories/struct').then(res => {
      this.struct = res.data
      this.$parent.init()
    })
  }
}
</script>

<style scoped>

</style>